import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import {
  addUser,
  editPassword,
  editUser,
  generateCode,
  getUsersData,
  updateUser,
} from "../../../services/userAPI";
import {
  setEditPasswordActions,
  setProfileActions,
  setshowDivsConditions,
  setSelectedRowsUser,
  setFilterVideosActions,
  setPaginationProps,
  setLoading,
  setURL,
} from "../store/userActions";
import { GetData } from "../../../ApiCalls";
import { useTranslation } from "react-i18next";
import User from "../user";
import { Helpers } from "./helpers";
import { GraphQLFetchData } from "./graphQLFetchData";
import { message } from "antd";
export const Hooks = () => {
  const { t } = useTranslation();
  const {acces_send} = Helpers()
  const [visible, setVisible] = useState<boolean>(false); // show Modal Details
  const [state, setState] = useState<boolean>(true);
  const [resetAddUser, setResetAddUser] = useState<boolean>(false);
  const [resetPasswordUser, setResetPasswordUser] = useState<boolean>(false);
  const [visibleDeletePopup, setVisibleDeletePopup] = useState<boolean>(false);
  const [visibleShowCodePopup, setVisibleShowCodePopup] = useState<any>(false);

  const [visibleMultiDeletePopup, setVisibleMultiDeletePopup] =
    useState<boolean>(false);
  const [visibleShowPasswordModal, setVisibleShowPasswordModal] =
    useState<boolean>(false);
  const searchgroup = useAppSelector(
    (state) => state.UserReducer.filterUsers.selectedGroup
  );
  const searchKey = useAppSelector(
    (state) => state.UserReducer.filterUsers.search
  );
  const dispatch = useDispatch();
  // const [formAddUser] = Form.useForm();
  // const [formEditPassword] = Form.useForm();
  // use Selector redux
  const values: any = useAppSelector(
    (state) => state.UserReducer.profileConstraintsData
  );
  const showDivCondition = useAppSelector(
    (state) => state.UserReducer.showDivsConditions
  );
  const initUsers = useAppSelector((state) => state.UserReducer.ListUsers);
  const valuesEditPassword = useAppSelector(
    (state) => state.UserReducer.editPasswordConstraintsData
  );
  //Pagination Props
  const paginationProps = useAppSelector(
    (state) => state.UserReducer.paginationProps
  );
  const [user, setUser] = useState<any>();
  // useEffect(()=>{
  //     formAddUser.setFieldsValue({
  //         firstname : values.firstname,
  //         lastname:values.lastname,
  //         nameUser:values.nameUser,
  //         email:values.email,
  //     })
  //     formEditPassword.setFieldsValue({
  //         oldPassword : valuesEditPassword.oldPassword,
  //         newPassword : valuesEditPassword.newPassword,
  //         confirmPassword : valuesEditPassword.confirmPassword
  //     })
  // },[values , valuesEditPassword])

  // **************************  FONCTION show Modal ********************//
  // fonction to hide Modal Details
  const {onDeleteUser} = GraphQLFetchData()

  const DeleteUsers = async(ids:number[],idTransferred:number)=>{
    onDeleteUser({variables:{input:{ids:ids,idTransferred:idTransferred}}}).then((data : any)=>{ 
      if(data?.data?.DeleteUsers?.message==="Users deleted successfully"){
        console.log('we are here')
        message.success({ content: t('Profile.successdelete'), duration: 2 }) 
      }
    })
    .catch((e)=> {
      message.error({ content: t('Profile.messageerroroperation'), duration: 2 })    });

  }

  const getRights = async () => {
    await GetData("/core/rights/get-rights-by-module").then(
      (res)=>{
        dispatch(
          setshowDivsConditions({
        showDivsConditionsName: "Authorized",
        showDivsConditionsValue: true
      }))  
      dispatch(
        setshowDivsConditions({
          showDivsConditionsName: "loading",
          showDivsConditionsValue: false,
        })
      );}
    ).catch((err)=>{
      dispatch({type:"SET_CORE_RIGHT_API",payload:true})
    })
  }

  const handleCancel = () => {
    setVisible(false);
  };
  //fonction to show Modal
  const showModal = (permission:string) => {
    acces_send(`${permission}_action`)
    setVisible(true);
  };
  const showModalPassword = (permission:string) => {
    acces_send(`${permission}_action`)
    setVisibleShowPasswordModal(true);
  };
  const handleCancelModalPassword = () => {
    setVisibleShowPasswordModal(false);
  };
  useEffect(() => {}, [user]);
  // ********************************* FONCTION Form add edit User ***************************************//
  const onFinish = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    await addUser({
      empProfilesUserForm: {
        ...values,
        tokenValidation: values.check,
        plainPassword: {
          first: values.password,
          second: values.confirmPassword,
        },
      },
    })
      .then((res) => {
        if (res.data.code === 200) {
          dispatch({ type: "SET_LOADING", payload: false });
          let user: any = {};
          if (values.check === true) {
            dispatch(
              setProfileActions({
                profileConstraintsDataNameChange: "id",
                profileConstraintsDataValueChange: res.data.id,
              })
            );
          } else {
            dispatch(
              setshowDivsConditions({
                showDivsConditionsName: "addUser",
                showDivsConditionsValue: false,
              })
            );
            GetUsersFilteredDataAndByGroup(paginationProps.pageSize, 0, searchKey, +searchgroup,paginationProps.order,paginationProps.desc);
          }
        }
      })
      .catch((err) => {
        const usernameError = {
          for: "username",
          messages: ["user.uniqueUsername", "Cette valeur est déjà utilisée."],
        };
        const emailError = {
          for: "email",
          messages: ["user.uniqueEmail", "Cette valeur est déjà utilisée."],
        };

        if (
          JSON.stringify(err.response.data.errors).includes(
            JSON.stringify(usernameError)
          ) &&
          JSON.stringify(err.response.data.errors).includes(
            JSON.stringify(emailError)
          )
        ) {
          dispatch({
            type: "SET_ERROR_USERNAME",
            payload: t("Profile.existUsername"),
          });
          dispatch({
            type: "SET_ERROR_EMAIL",
            payload: t("Profile.existEmail"),
          });
        } else {
          if (
            JSON.stringify(err.response.data.errors[0]) ===
            JSON.stringify(usernameError)
          ) {
            dispatch({
              type: "SET_ERROR_USERNAME",
              payload: t("Profile.existUsername"),
            });
          } else dispatch({ type: "SET_ERROR_USERNAME", payload: "" });

          if (
            JSON.stringify(err.response.data.errors[0]) ===
            JSON.stringify(emailError)
          ) {
            dispatch({
              type: "SET_ERROR_EMAIL",
              payload: t("Profile.existEmail"),
            });
          } 
          else dispatch({ type: "SET_ERROR_EMAIL", payload: "" });
        }
      });
    dispatch({ type: "SET_LOADING", payload: false });
    dispatch(
      setPaginationProps({
        PaginationPropsNameChange: "current",
        PaginationPropsValueChange: 1,
      })
    );

  };
  const onFinishUpdateUser = async () => {
    dispatch(setLoading(true));
    updateUser({ ...values, tokenValidation: values.check })
      .then((res: any) => {
        if (res.data.code === 200) {
          dispatch({ type: "SET_LOADING", payload: false });
          dispatch({ type: "SET_ERROR_EMAIL", payload: "" });
          dispatch({ type: "SET_ERROR_USERNAME", payload: "" });

          dispatch(
            setProfileActions({
              profileConstraintsDataNameChange: "id",
              profileConstraintsDataValueChange: values.id,
            })
          );

          dispatch(
            setshowDivsConditions({
              showDivsConditionsName: "editUser",
              showDivsConditionsValue: false,
            })
          );
          GetUsersFilteredDataAndByGroup(10, 0, searchKey, +searchgroup,paginationProps.order,paginationProps.desc);

          dispatch(
            setPaginationProps({
              PaginationPropsNameChange: "current",
              PaginationPropsValueChange: 1,
            })
          );
        }
      })
      .catch((err) => {
        const usernameError = {
          for: "username",
          messages: ["user.uniqueUsername", "Cette valeur est déjà utilisée."],
        };
        const emailError = {
          for: "email",
          messages: ["user.uniqueEmail", "Cette valeur est déjà utilisée."],
        };

        if (
          JSON.stringify(err.response.data.errors).includes(
            JSON.stringify(usernameError)
          ) &&
          JSON.stringify(err.response.data.errors).includes(
            JSON.stringify(emailError)
          )
        ) {
          dispatch({
            type: "SET_ERROR_USERNAME",
            payload: t("Profile.existUsername"),
          });
          dispatch({
            type: "SET_ERROR_EMAIL",
            payload: t("Profile.existEmail"),
          });
        } else {
          if (
            JSON.stringify(err.response.data.errors[0]) ===
            JSON.stringify(usernameError)
          ) {
            dispatch({
              type: "SET_ERROR_USERNAME",
              payload: t("Profile.existUsername"),
            });
          } else dispatch({ type: "SET_ERROR_USERNAME", payload: "" });

          if (
            JSON.stringify(err.response.data.errors[0]) ===
            JSON.stringify(emailError)
          ) {
            dispatch({
              type: "SET_ERROR_EMAIL",
              payload: t("Profile.existEmail"),
            });
          } 
          else dispatch({ type: "SET_ERROR_EMAIL", payload: "" });
        }
      });
      dispatch({ type: "SET_LOADING", payload: false });
      dispatch(
        setPaginationProps({
          PaginationPropsNameChange: "current",
          PaginationPropsValueChange: 1,
        })
      );
  };

  const onFinishEditUser = async () => {
    await editUser(values);
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onReset = () => {
    setResetAddUser(true);
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "addUser",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "editUser",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "editPassword",
        showDivsConditionsValue: false,
      })
    );
    // dispatch inputs profile user
    onResetValuesAdd();
    dispatch({ type: "SET_ERROR_USERNAME", payload: false });

    dispatch({ type: "SET_ERROR_EMAIL", payload: false });
  };
  const onResetValuesAdd = () => {
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "id",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "firstName",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "lastName",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "username",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "email",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "phone",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "mobile",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "group",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "channels",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "description",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "password",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "confirmPassword",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "adresse",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "vignette",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "check",
        profileConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "postalCode",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "city",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "society",
        profileConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "numberPerson",
        profileConstraintsDataValueChange: 0,
      })
    );
    dispatch(setURL(""));
  };
  // ********************************* FONCTION Form Edit Password ***************************************//
  const onFinishEditPassword = async (id: any, passwords: any) => {
    editPassword(id, passwords)
      .then((res: any) => {
        if (res.data.code == 200) {
          handleCancelModalPassword();
          dispatch({ type: "SET_BTN_PASSWORD", payload: false });
        }
      })

      .catch((err) => console.log(err));
    //editPasswordAction(values.id,{first:values.password,second:values.confirmPassword})
  };

  const onFinishFailedEditPassword = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const onResetEditPassword = async () => {
    setResetPasswordUser(true);
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "editPassword",
        showDivsConditionsValue: false,
      })
    );
    //Reset Inputs Edit Password
    dispatch(
      setEditPasswordActions({
        editPasswordDataNameChange: "oldPassword",
        editPasswordDataValueChange: "",
      })
    );
    dispatch(
      setEditPasswordActions({
        editPasswordDataNameChange: "newPassword",
        editPasswordDataValueChange: "",
      })
    );
    dispatch(
      setEditPasswordActions({
        editPasswordDataNameChange: "confirmPassword",
        editPasswordDataValueChange: "",
      })
    );
  };

  const onValueFormUserchnage = (val: any) => {
    // Dispatch Value of Select Type Security
    if (val.type) {
      dispatch(
        setProfileActions({
          profileConstraintsDataNameChange: "type",
          profileConstraintsDataValueChange: val.type,
        })
      );
    }
    // Dispatch Value of Select Canaux Security
    if (val.channels) {
      dispatch(
        setProfileActions({
          profileConstraintsDataNameChange: "channels",
          profileConstraintsDataValueChange: val.channels,
        })
      );
    }
    // Dispatch Value of Select Groupe Security
    if (val.groupe) {
      dispatch(
        setProfileActions({
          profileConstraintsDataNameChange: "groupe",
          profileConstraintsDataValueChange: val.groupe,
        })
      );
    }
  };

  //********************** handle changle inputs profile *************************//
  const handleChangeInputs = (event: any) => {
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: event.target.name,
        profileConstraintsDataValueChange: event.target.value,
      })
    );
    event.target.value === "" ? setState(true) : setState(false);
  };
  const handleChangeThumb = (value: any) => {
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "vignette",
        profileConstraintsDataValueChange: value,
      })
    );
  };
  const handleChangeConnectionType = (value: any) => {
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "connectionType",
        profileConstraintsDataValueChange: value,
      })
    );
  };
  const handleChangeGroup = (obj: any) => {
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "group",
        profileConstraintsDataValueChange: obj,
      })
    );
  };
  const handleChangeChannel = (obj: any) => {
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "channels",
        profileConstraintsDataValueChange: obj,
      })
    );
  };
  const handleChangeMobile = (obj: any) => {
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "mobile",
        profileConstraintsDataValueChange: obj,
      })
    );
  };
  const handleChangePhone = (obj: any) => {
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "phone",
        profileConstraintsDataValueChange: obj,
      })
    );
  };

  // ******************* Checkbox function **************//
  const handleChangeCheckbox = (e: any,permission:any) => {
    if(e.target.checked){
      acces_send(`${permission}_action`)
    }
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "check",
        profileConstraintsDataValueChange: e.target.checked,
      })
    );
  };

  // ********************************* FONCTION add edit User ***************************************//

  /// ***************************** Fonction Header **********************************///
  const handleAddUser = (permission:string) => {
    acces_send(`${permission}_action`)
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "addUser",
        showDivsConditionsValue: true,
      })
    );
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "editUser",
        showDivsConditionsValue: false,
      })
    );
    setshowDivsConditions({
      showDivsConditionsName: "editPassword",
      showDivsConditionsValue: false,
    });
  };
  /// ***************************** Fonction Header **********************************///

  ///////////////////******************* fonction Security Password ************************************///////////
  const onBlur = () => {
    // console.log("blur");
  };

  const onFocus = () => {
    // console.log("focus");
  };

  const onSearch = (val: any) => {
    // console.log("search:", val);
  };
  const onChange = (value: any) => {
    // console.log(`selected ${value}`);
  };

  ///////// ***************** handle Edit Password  ******************//////
  const handleEditPassword = () => {
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "editPassword",
        showDivsConditionsValue: true,
      })
    );
  };

  /////********************** Edit User ******************/////////
  const EditUser = (record: any,permission:string) => {
    acces_send(`${permission}_action`)
    console.log(record.row.vignette,'record.row.vignette');
    
    dispatch(
      setshowDivsConditions({
        showDivsConditionsName: "editUser",
        showDivsConditionsValue: true,
      })
    );
    // dispatch inputs profile user
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "id",
        profileConstraintsDataValueChange: record.row.id,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "firstName",
        profileConstraintsDataValueChange: record.row.firstName,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "lastName",
        profileConstraintsDataValueChange: record.row.lastName,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "username",
        profileConstraintsDataValueChange: record.row.username,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "email",
        profileConstraintsDataValueChange: record.row.email,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "group",
        profileConstraintsDataValueChange: record.row.group.id,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "channels",
        profileConstraintsDataValueChange: record.row.channels,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "description",
        profileConstraintsDataValueChange: record.row.description,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "adresse",
        profileConstraintsDataValueChange: record.row.adresse,
      })
    );
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "vignette",
        profileConstraintsDataValueChange: record.row.vignette,
      })
    );
  };
  //*********************** search header **************///////
  const handleSearchRow = async (event: any) => {
    if (event.key === "Enter") {
      await dispatch(
        setFilterVideosActions({
          FilterUsersNameChange: "search",
          FilterUsersValueChange: event.target.value,
        })
      );
    }
  };

  const GetAllUsers = async (
    ) => {
  
  
  
      await GetData(
        `/video-api/user/?columns%5B0%5D%5Borderable%5D=true&idGroup=0&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc`
      )
        .then((res) => {
          dispatch({ type: "GET_ALL_USERS_DATA", payload: res.data.data });
        })
        .catch((err) => {
          console.log(err);
        });
  
  
    };
  const GetUsersFilteredData = async (
    length: any,
    start: any,
    search: any,
    idGroup: number
  ) => {
    setTimeout(() => {
      dispatch(setLoading(true));
    }, 500);
    dispatch(
      setFilterVideosActions({
        FilterUsersNameChange: "search",
        FilterUsersValueChange: search,
      })
    );
    dispatch(
      setFilterVideosActions({
        FilterUsersNameChange: "selectedGroup",
        FilterUsersValueChange: idGroup,
      })
    );

    await GetData(
      `/video-api/user/?columns%5B0%5D%5Borderable%5D=true&length=${length}&idGroup=${idGroup}&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&search%5Bvalue%5D=${search}&start=${start}`
    )
      .then((res) => {
        dispatch({ type: "GET_USERS_DATA", payload: res.data.data });
        dispatch({
          type: "SET_TOTAL_RECORDS",
          payload: res.data.data.recordsFiltered,
        });

        if (search === "" && !searchgroup)
          dispatch(
            setPaginationProps({
              PaginationPropsNameChange: "current",
              PaginationPropsValueChange: 1,
            })
          );
      })
      .catch((err) => {
        console.log(err);
      });

    clearTimeout(500);
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 500);
  };
  const GetUsersFilteredDataAndByGroup = async (
    length: any,
    start: any,
    search: any,
    idGroup: number,
    order:any,
    desc:any
  ) => {
    dispatch({ type: "SET_LOADING", payload: true });

    dispatch(
      setFilterVideosActions({
        FilterUsersNameChange: "search",
        FilterUsersValueChange: search,
      })
    );
    dispatch(
      setFilterVideosActions({
        FilterUsersNameChange: "selectedGroup",
        FilterUsersValueChange: idGroup,
      })
    );
    await GetData(
      `/video-api/user/?columns%5B0%5D%5Borderable%5D=true&length=${length}&idGroup=${idGroup}&order[${order}][column]=${order}&order[${order}][dir]=${desc}&search%5Bvalue%5D=${search}&start=${start}`
    )
      .then((res) => {
        dispatch({ type: "GET_USERS_DATA", payload: res.data.data });
        dispatch({
          type: "SET_TOTAL_RECORDS",
          payload:  res.data.data.recordsFiltered,
        });
      })

      .catch((err) => {
        console.log(err);
      });

    dispatch({ type: "SET_LOADING", payload: false });
  };
  const GetUsersFilteredDataByGroup = async (
    length: number,
    start: number,
    idGroup: any,
    order:any,
    desc:any
  ) => {
    dispatch({ type: "SET_LOADING", payload: true });
    dispatch(
      setFilterVideosActions({
        FilterUsersNameChange: "selectedGroup",
        FilterUsersValueChange: idGroup,
      })
    );
    await GetData(
      `/video-api/user/?columns%5B0%5D%5Borderable%5D=true&length=${length}&draw=1&idGroup=${idGroup}&order[${order}][column]=${order}&order[${order}][dir]=${desc}&search%5Bvalue%5D=&start=${start}`
    )
      .then((res) => {
        dispatch({ type: "GET_USERS_DATA", payload: res.data.data });
        dispatch({
          type: "SET_TOTAL_RECORDS",
          payload: res.data.data.recordsFiltered,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    dispatch({ type: "SET_LOADING", payload: false });
  };
  const handleChangeSelect = (event: any) => {
    dispatch(
      setProfileActions({
        addGroupConstraintsDataNameChange: "roles",
        addGroupConstraintsDataValueChange: event.target.value,
      })
    );
  };

  const handleCancelDeletePopup = () => {
    setVisibleDeletePopup(false);
  };
  const handleCancelCodePopup = () => {
    dispatch({ type: "SHOW_MODAL_CODE", payload: false });
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "check",
        profileConstraintsDataValueChange: false,
      })
    );
  };
  const handleCancelMultiDeletePopup = () => {
    setVisibleMultiDeletePopup(false);
  };
  const onChangeFolder = async (e: any) => {
    await dispatch(
      setFilterVideosActions({
        FilterUsersNameChange: "type",
        FilterUsersValueChange: e,
      })
    );
  };
  const showModalDeletePopup = () => {
    setVisibleDeletePopup(true);
  };
  const showCodePopup = () => {
    setVisibleShowCodePopup(true);
    dispatch(
      setProfileActions({
        profileConstraintsDataNameChange: "check",
        profileConstraintsDataValueChange: true,
      })
    );
  };
  const setUsersSelectedEmpty = () => {
    dispatch(setSelectedRowsUser([]));
    dispatch(
      setPaginationProps({
        PaginationPropsNameChange: "id",
        PaginationPropsValueChange: [],
      })
    );
  };

  const showModalMultiDeletePopup = () => {
    setVisibleMultiDeletePopup(true);
  };
  const GetUsersData = async (length: any, start: any, search: string, order:any, desc:any) => {
    dispatch({ type: "SET_LOADING", payload: true });
    await GetData(
      `/video-api/user/?columns%5B0%5D%5Borderable%5D=true&length=${length}&order[${order}][column]=${order}&order[${order}][dir]=${desc}&search%5Bvalue%5D=${search}&start=${start}&draw=1`
    )
      .then((res) => {
        dispatch({ type: "GET_USERS_DATA", payload: res.data.data });
        dispatch({
          type: "SET_TOTAL_RECORDS",
          payload: res.data.data.recordsFiltered,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(setLoading(false));
  };

  return {
    visible,
    setUsersSelectedEmpty,
    handleCancel,
    showModal,
    onFinish,
    onFinishFailed,
    onFinishEditUser,
    onReset,
    // formAddUser,
    handleChangeInputs,
    values,
    state,
    handleAddUser,
    onBlur,
    onFocus,
    onSearch,
    onChange,
    handleEditPassword,
    onFinishEditPassword,
    onFinishFailedEditPassword,
    onResetEditPassword,
    // formEditPassword,
    onValueFormUserchnage,
    handleChangeCheckbox,
    valuesEditPassword,
    EditUser,
    handleSearchRow,
    onChangeFolder,
    paginationProps,
    handleChangeGroup,
    handleChangeChannel,
    visibleDeletePopup,
    handleCancelDeletePopup,
    showModalDeletePopup,
    showModalMultiDeletePopup,
    handleCancelMultiDeletePopup,
    visibleMultiDeletePopup,
    GetUsersData,
    GetUsersFilteredData,
    handleChangeSelect,
    handleChangeThumb,
    handleChangeConnectionType,
    GetUsersFilteredDataByGroup,
    handleChangeMobile,
    handleChangePhone,
    handleCancelCodePopup,
    showCodePopup,
    visibleShowCodePopup,
    onResetValuesAdd,
    visibleShowPasswordModal,
    handleCancelModalPassword,
    showModalPassword,
    GetUsersFilteredDataAndByGroup,
    onFinishUpdateUser,
    getRights,
    GetAllUsers,
    DeleteUsers
  };
};
