import React, {useState} from 'react';
// import EditIcon from '@mui/icons-material/Edit';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import{ Button , Tooltip , Modal , Box , Typography ,TextField} from '@mui/material';
import { useHistory } from 'react-router-dom';
import IconButton from "@material-ui/core/IconButton";
import {Hooks} from "../../utils/hooks";
import {useTranslation} from "react-i18next";
import { useAppSelector } from '../../../../redux/hooks';
import { ModalInputs } from '@empreinte/components.modal-inputs';
import { InputAdornment } from "@material-ui/core";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {ModalConfirm} from "@empreinte/components.modal-confirm"
import AddIcon from '@mui/icons-material/Add';
import { Helpers } from '../../../user/utils/helpers';


function useActionMenu({record}:any) {
  const {profiles_ip_module} = useAppSelector((state)=>state.ReducerUserData.modules)
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)

const history = useHistory();
const {acces_send} = Helpers()
const {t} = useTranslation();
const {showModal,handleCancel,visible , handleClickEdit,DeleteIp}=Hooks()
const detail = t("Profile.details");
const edit = t("Profile.edit") ;
const delet = t("Profile.delete") ; 
const [confirmationDelete,setConfirmationDelete]= useState(false)
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    overflow:'scroll',
    height:410, 
    pl : 3 ,
    pr : 1 ,
    py : 1 , 
  };
    return(
        <div>
            <div>
              <div  className="actionIp" >
                {(profiles_ip_module?.profiles_ip_get_detail_action?.authorized || isSuperAdmin ) &&  
                  <div has-permission="profiles_ip_get_detail" ><Tooltip placement="top" title={detail}><RemoveRedEyeIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>showModal(profiles_ip_module?.profiles_ip_get_detail_action?.name)}/></Tooltip></div>
                 }
              {(profiles_ip_module?.profiles_ip_post_edit_action?.authorized || isSuperAdmin ) &&
                <div has-permission="profiles_ip_post_edit" ><Tooltip placement="top" title={edit}><ModeEditIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>handleClickEdit(record,profiles_ip_module?.profiles_ip_post_edit_action?.name)}/></Tooltip></div>
              } 
               {(profiles_ip_module?.profiles_ip_delete_ips_action?.authorized || isSuperAdmin ) &&  
                <div has-permission="profiles_ip_delete_ips" ><Tooltip placement="top" title={delet}><DeleteIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>setConfirmationDelete(true)} /></Tooltip></div>
                }  
              </div>
        {confirmationDelete ? (
          <div>
            <ModalConfirm 
            className="config-ip-modal"
            open={confirmationDelete}
            fullWidth
            onClose={()=>setConfirmationDelete(false)}
            title={t("Profile.DeleteConfirmation")}
            content={t("Profile.DeleteConfirmationContent")}
            CancelBtnName={t("Profile.cancel")}
            onCancel={()=>setConfirmationDelete(false)}
            ConfirmBtnName={t("Profile.yes")}
            onConfirm={()=>{acces_send(`${profiles_ip_module?.profiles_ip_delete_ips_action?.name}_action`);DeleteIp([record?.row?.id]);setConfirmationDelete(false)}}
            />

        </div>
      ) : <></>}

      <ModalInputs
        className="modal-ip-details" 
        open={visible}
        dividers
        title={`${t("Profile.modaledittitle")} ${record.row.ip}`}
        onClose={handleCancel}
        CancelBtnName={t("Profile.close")}
        onCancel={handleCancel}
        children={
          <div className="ip-details-modal">
            <div className="ip-details-modal-container">
              <span className="ip-details-modal-title">{"ID"}</span>
              <span className="ip-details-modal-value">{record.row.id ? record.row.id : "--"}</span>
            </div>
            <div className="ip-details-modal-container">
              <span className="ip-details-modal-title">{t("Profile.adressIp")}</span>
              <span className="ip-details-modal-value">{record.row.ip ? record.row.ip : "--"}</span>
            </div>
            <div className="ip-details-modal-container">
              <span className="ip-details-modal-title">{"Description"}</span>
              <span className="ip-details-modal-value ip-details-modal-valuemore">{record.row.description ? record.row.description : "--"}</span>
            </div>
          </div>
        }
        />
            </div>
        </div>
    );
}

export default useActionMenu;