import axios from 'axios';

const search = window.location.search;
const token = new URLSearchParams(search).get("token");

axios.defaults.headers.common = {
    Authorization: `Bearer ${token}` ,
    'Content-Type':  'application/json',
};

export const getUsersData = async()=>{
    return await axios.get(`https:${window.process.env.API_HOST_GLOBAL}/dataTable/users`)
}

export const addUser = async(data:any)=>{
    return await axios.post(`https:${window.process.env.API_HOST_GLOBAL}/profiles/user/add`,{...data})
}
export const updateUser = async (data:any) => {
    let obj = {}
    if(data && data.tokenValidation) {
        obj = {id:data.id, tokenValidation: data.tokenValidation, empProfilesUserForm :{...data}}
    } else {
        obj = {id:data.id, empProfilesUserForm: {...data}}
    }
    return await axios.post(`https:${window.process.env.API_HOST_GLOBAL }/profiles/user/edit/${data.id}` , obj )
};
export const editUser = async (id : any) => {
    return await axios.get(`https:${window.process.env.API_HOST_GLOBAL}/profiles/details/${id}/user` )
};
export const deleteUser = async(id:any,attributeId:any)=>{
    return await axios.delete(`https:${window.process.env.API_HOST_GLOBAL}/profiles/delete-user/${id}/${attributeId}`)
}
export const getChannels=async()=>{
    return await axios.get(`https:${window.process.env.API_HOST_GLOBAL}/channel/select-choices`)
}
export const getGroups=async()=>{
return await axios.get(`https:${window.process.env.API_HOST_GLOBAL}/dataTable/groups`)
}
export const uploadImage=async(formData:any)=>{
    return await axios.post(`https:${window.process.env.API_HOST_GLOBAL}/profiles/user/upload/thumb`,formData)
}
export const editPassword=async(id:any,data:any)=>{
    return await axios.post(`https:${window.process.env.API_HOST_GLOBAL}/profiles/user/${id}/password/edit`,{is:id,empProfilesUserForm:{plainPassword:{...data}}})
}
export const generateCode= async(data:any)=>{
    return await axios.post(`https:${window.process.env.API_HOST_GLOBAL}/profiles/user/generate-validation-code`,{...data})
}

export const checkValidationCode= async(data:any)=>{
    return await axios.post(`https:${window.process.env.API_HOST_GLOBAL}/profiles/user/check-validation-code`,{...data})
}
export const enableDisableUser = async(action:any,id:any)=>{
    return await axios.post(`https:${window.process.env.API_HOST_GLOBAL }/profiles/user/${action}/${id}`,{id:id})
}
export const multiDelete=async(ids:Number[])=>{
    let stringids=""
    for(var i=0;i<ids.length;i++){
        stringids=stringids+","+ids[i]
    }

    return await axios.delete(`https:${window.process.env.API_HOST_GLOBAL }/multiDelete/users/${stringids}`)
}
export const getValidationCode=async(id:Number)=>{
    return await axios.get(`https:${window.process.env.API_HOST_GLOBAL }/profiles/user/use-validation-code/${id}`)
}