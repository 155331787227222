import React, { useState } from "react";
import { Hooks } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import {
  EditOutlined,
  DeleteOutlineOutlined,
  HighlightOffOutlined,
  VisibilityOutlined,
  LockOutlined,
} from "@mui/icons-material";
import LockIcon from '@mui/icons-material/Lock';
import {
  Button,
  Tooltip,
  Modal,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import IconButton from "@material-ui/core/IconButton";
import { useAppSelector } from "../../../../redux/hooks";
import { ModalInputs } from '@empreinte/components.modal-inputs';
import {ModalConfirm} from "@empreinte/components.modal-confirm"
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Helpers } from "../../../user/utils/helpers";

function useActionMenu({ record }: any) {
  const { t } = useTranslation();
  const {profiles_group_module} = useAppSelector(state =>state.ReducerUserData.modules)
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)
  const {acces_send} = Helpers()
  const {
    handleUpdateGroup,
    showModal,
    handleCancel,
    visible,
    handleClickPermission,
    DeleteGroup,
    getPermissions
  } = Hooks();
 
  const detail = t("Profile.details");
  const edit = t("Profile.edit");
  const permission = t("Profile.managingPermission");
  const delet = t("Profile.delete");
  const [confirmationDelete,setConfirmationDelete]= useState(false)


  return (
    <div>
      
      <div className="actionGroup">
        {(profiles_group_module?.profiles_group_get_detail_action?.authorized || isSuperAdmin) &&
              <div has-permission="profiles_group_get_detail" ><Tooltip placement="top" title={detail}><RemoveRedEyeIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>showModal(profiles_group_module?.profiles_group_get_detail_action?.name)}/></Tooltip></div>
        }
        {(profiles_group_module?.profiles_group_post_edit_action?.authorized || isSuperAdmin) &&
            <div has-permission="profiles_group_post_edit" ><Tooltip placement="top" title={edit}><ModeEditIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>handleUpdateGroup(record,profiles_group_module?.profiles_group_post_edit_action?.name)}/></Tooltip></div>
          }
          {(profiles_group_module?.profiles_group_permission_no_api_action?.authorized || isSuperAdmin) && 
            <div has-permission="profiles_group_permission_no_api" ><Tooltip placement="top" title={permission}><LockIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>{acces_send(`${profiles_group_module?.profiles_group_permission_no_api_action?.name}_action`);getPermissions(record.row.id)}}/></Tooltip></div>
          }
        {(profiles_group_module?.profiles_group_delete_group_action?.authorized || isSuperAdmin) &&
          <div has-permission="profiles_group_delete_group" ><Tooltip placement="top" title={delet}><DeleteIcon color="action" fontSize="small" className={"icon_action"} onClick={()=>setConfirmationDelete(true)} /></Tooltip></div>
        }
        </div>
        {confirmationDelete ? (
          <div>
            <ModalConfirm 
            className="config-group-modal"
            open={confirmationDelete}
            fullWidth
            onClose={()=>setConfirmationDelete(false)}
            title={t("Profile.DeleteConfirmation")}
            content={t("Profile.DeleteConfirmationContentGroup")}
            CancelBtnName={t("Profile.cancel")}
            onCancel={()=>setConfirmationDelete(false)}
            ConfirmBtnName={t("Profile.confirm")}
            onConfirm={()=>{acces_send(`${profiles_group_module?.profiles_group_delete_group_action?.name}_action`);DeleteGroup([record.row.id]);setConfirmationDelete(false)}}
            />

        </div>
      ) : <></>}


        <ModalInputs
          className="modal-group-details" 
          open={visible}
          dividers
          title={`${t("Profile.modaledittitlegroup")} ${record.row.name}`}
          onClose={handleCancel}
          CancelBtnName={t("Profile.close")}
          onCancel={handleCancel}
          children={
            <div className="group-details-modal">
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.nameGroup")}</span>
                <span className="group-details-modal-value">{record.row.name ? record.row.name : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.roles")}</span>
                <span className="group-details-modal-value">{record.row.roles ? record.row.roles : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{"Description"}</span>
                <span className="group-details-modal-valuemore">{record.row.description ? record.row.description : "--"}</span>
              </div>
            </div>
          }
        />
      </div>
  );
}

export default useActionMenu;
