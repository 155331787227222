import React, {useEffect} from "react";
import {useMutation, useLazyQuery , useQuery} from "@apollo/react-hooks";
import {graphQL_shema} from "./graphQL";
import {Hooks} from "./hooks";
import {useDispatch, useSelector} from "react-redux";
import {useAppSelector} from "../../../redux/hooks";
import {setshowUsersActions} from "../store/userActions";


export const GraphQLFetchData=()=> {
    const dispatch = useDispatch()
    //Pagination Props
    const paginationProps= useAppSelector((state) => state.UserReducer.paginationProps)
    //Filter Props
    const values= useAppSelector((state) => state.UserReducer.filterUsers)



    // Get ALL USERS
    // use Query to fetch Data
    const [dataUsers,{data:dataUsersUpdated}]
        = useMutation(graphQL_shema().Get_All_Users, {
            fetchPolicy:  "no-cache",
             variables: { },
        onCompleted :(data)=>{
            if(data?.Users){
          
                dispatch(setshowUsersActions(data?.Users));
            }else {

            }}
           

        
    })
    const [onDeleteUser] = useMutation(
        graphQL_shema().Delete_Users,
        {
          fetchPolicy: "no-cache",
          variables: {},
          onCompleted: () => {
               
          },
          onError:()=>{
    
          }
        }
      );


    return({
        dataUsers,
        dataUsersUpdated,
        paginationProps,
        values,
        onDeleteUser

    })
}
