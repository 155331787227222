import React, {useEffect, useState} from "react";
import {PostData,GetData,DeleteData,UpdateData} from "../../../ApiCalls"
import {useDispatch} from "react-redux";
import {GraphQLFetchData} from './GraphQLFetchData'
import {setAddIPActions, setPaginationPropsIP, setshowDivsConditionsIP,setIpToDelete} from "../store/ipActions";
import {useAppSelector} from "../../../redux/hooks";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { Helpers } from "../../user/utils/helpers";
export const Hooks = () =>{
    const [visibleMultiDeletePopup, setVisibleMultiDeletePopup] = useState<boolean>(false)
    const {GetListIps,CreateIp,UpdateIp,onDeleteIp} = GraphQLFetchData()
    const [visible , setVisible] = useState<any>(false)
    const dispatch = useDispatch()
    const {acces_send} = Helpers()
    const values = useAppSelector((state) => state.IPReducer.addIpConstraintsData)
    const [pagination,setPagination] = useState(useAppSelector((state) => state.IPReducer.paginationPropsIP))
    const { t } = useTranslation();
    const [showMultiDeleteIp,setShowMultiDeleteIp] = useState(false)
    useEffect(()=>{
       
    },[pagination])

    const handleShowMultiuDeleteIp = ()=>{
        setShowMultiDeleteIp(true)
    }
    const handlecloseMultiuDeleteIp = ()=>{
        setShowMultiDeleteIp(false)
    }
    const isIPv4 = (ip : string) => {
        const Reg = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        return Reg.test(ip);
      }; 
      function isIPv6(ip:string) {
        const ipv6Pattern = /^([0-9A-Fa-f]{1,4}:)*([0-9A-Fa-f]{1,4}:)*[0-9A-Fa-f]{1,4}$/;

        if (ipv6Pattern.test(ip)) {
          const segments = ip.split(":");
          const numSegments = segments.length;      
          // If there are more than 8 segments, it's invalid
          if (numSegments <= 8) {
            // If there are less than 8 segments and one of them is empty, it's valid
            if (numSegments < 8 && segments.indexOf("") !== -1) {
              const doubleColonIndex = ip.indexOf("::");      
              // There should be only one double colon
              if (doubleColonIndex === ip.lastIndexOf("::")) {
                const beforeColonCount = ip.substring(0, doubleColonIndex).split(":").filter(Boolean).length;
                const afterColonCount = ip.substring(doubleColonIndex + 2).split(":").filter(Boolean).length;     
                // Check that the total number of segments before and after "::" plus the "::" itself is less than or equal to 7
                if (beforeColonCount + afterColonCount + 2 <= 7) {
                  return true;
                }
              }
            } else if (numSegments === 8) {
              // Check that all segments are valid
              return segments.every(seg => /^[0-9A-Fa-f]{1,4}$/.test(seg));
            }
          }
        }     
        return false;
      }
    const actionAddEdit=()=>{   
       if(values.descIP === "" || values.ipAdress === "" || (!isIPv4(values.ipAdress) && !isIPv6(values.ipAdress))){
        dispatch(setshowDivsConditionsIP({
            showDivsConditionsName: "action",
            showDivsConditionsValue: true
        }))
       }else{
        dispatch(setshowDivsConditionsIP({
            showDivsConditionsName: "action",
            showDivsConditionsValue: false
        }))
       }
    }

    const Search_Data =  async (value:any) => {
        dispatch(setPaginationPropsIP({
            PaginationPropsNameChange: "current",
            PaginationPropsValueChange: 1
        }))
      
    };  
  
    const AddIp = async () => {
        CreateIp({variables:{input:{ip:values.ipAdress,description:values.descIP}}})
  };

    const DeleteIp =  async (id : any) => {
        onDeleteIp({variables:{ids:id}}).then((data : any)=>{ 

            if(data?.data?.DeleteMultipleIp?.deleteditems != null)
           { 
            message.success({ content: t('Profile.successdelete'), duration: 2 }) 
            dispatch(setIpToDelete(id))
             return
          }
          dispatch(setIpToDelete([]))
          message.error({ content: t('Profile.messageerroroperation'), duration: 2 }) 
          })
          .catch(()=> {dispatch(setIpToDelete([])) ;message.error({ content: t('Profile.messageerroroperation'), duration: 2 })
        });
    
    }
    const editIp = async () => {
        UpdateIp({variables:{input:{id:values.Id,ip:values.ipAdress,description:values.descIP}}})
    };


    const handleCancelMultiDeletePopup = () => {
        setVisibleMultiDeletePopup(false)
     };
    const showModalMultiDeletePopup = () => {
        setVisibleMultiDeletePopup(true)
      }


    /////****************** show Modal ******************////////
    const showModal = (permission:string) =>{
      acces_send(`${permission}_action`)
        setVisible(true)
    }
    const handleCancel = () =>{
        setVisible(false)
    }
    /////************************* Add Ip **************////////
    const handleAddIp = (permission:string) => {
      acces_send(`${permission}_action`)
        dispatch(
            setshowDivsConditionsIP({
                showDivsConditionsName: "addIP",
                showDivsConditionsValue: true,
            })
        );
     
    }
 
    const onResetIP = () => {
        dispatch(
            setshowDivsConditionsIP({
                showDivsConditionsName: "addIP",
                showDivsConditionsValue: false,
            })
        );
        dispatch(
            setshowDivsConditionsIP({
                showDivsConditionsName: "editIP",
                showDivsConditionsValue: false,
            })
        );
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "ipAdress",
            addIpConstraintsDataValueChange: ""
        }));
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "descIP",
            addIpConstraintsDataValueChange: ""
        }));
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "Id",
            addIpConstraintsDataValueChange: ""
        }));
    }
   
    const onValueFormUserchangeIP = () =>{

    }
    const handleChangeInputs = (event:any) => {
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: event.target.name,
            addIpConstraintsDataValueChange: event.target.value
        }));
    }
    
    const handleChangeInputsIdNum = (event:any) => {      
          const inputValue = event.target.value ?? "";
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: event.target.name,
            addIpConstraintsDataValueChange:inputValue
        }));
    }
    const handleClickEdit = (record:any,permission:string) => {
      acces_send(`${permission}_action`)
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "Id",
            addIpConstraintsDataValueChange: record.row.id
        }));
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "ipAdress",
            addIpConstraintsDataValueChange: record.row.ip
        }));
        dispatch(setAddIPActions({
            addIpConstraintsDataNameChange: "descIP",
            addIpConstraintsDataValueChange: record.row.description
        }));
        dispatch(
            setshowDivsConditionsIP({
                showDivsConditionsName: "editIP",
                showDivsConditionsValue: true,
            })
        );
    }

      const handleSearchRow = async (event: any) => {
        if (event.keyCode == 13) {
            dispatch(Search_Data(event.target.value))
          } else if (event === "") {
            dispatch(Search_Data(event))
          }
      };


      const handelSearch = (word: string, tab: any) => {
        if (word.trim() === "") return tab;
    
        const res = tab.filter((obj: any) => {
          if (obj.Type.toLowerCase().includes(word.toLowerCase())) return obj;
        });
    
        return res;
      };
      const handelFilter = (type: any, tab: any) => {
        function smallval(item: any) {
          if (type === "All") {
            return item;
          } else if (type !== "") {
            return item.Type === type;
          } else {
            return item;
          }
        }
    
        const res = tab.filter(smallval);
    
        return res;
      };
    
    
    return({
        visibleMultiDeletePopup,
        handelFilter,
        handelSearch,
        showModal,
        handleCancel,
        showModalMultiDeletePopup,
        handleCancelMultiDeletePopup,
        visible,
        handleAddIp,
        onResetIP,
        onValueFormUserchangeIP,
        handleChangeInputs,
        handleChangeInputsIdNum,
        handleClickEdit,
        AddIp,
        DeleteIp,
        editIp,
        handleSearchRow,
        actionAddEdit,
        handleShowMultiuDeleteIp,
        handlecloseMultiuDeleteIp,
        showMultiDeleteIp,
        isIPv4,
        isIPv6
     
    })

}
