import React, { useEffect } from "react";
import {
  EditOutlined,
  DeleteOutlineOutlined,
  PersonRemove,
  LockOutlined,
  VisibilityOutlined,
  HighlightOffOutlined,
  PersonAdd
} from "@mui/icons-material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Tooltip,
  Modal,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { Hooks } from "../../utils/hooks";
import { InputText } from "@empreinte/components.input-text";
// redux

import { useTranslation } from "react-i18next";
import { useAppSelector,useAppDispatch } from "../../../../redux/hooks";
import { enableDisableUserAction, setLoading, setPaginationProps, setProfileActions } from "../../store/userActions";
import { deleteUser, editUser } from "../../../../services/userAPI";
import { useHistory } from "react-router-dom";
import { ModalConfirm } from "@empreinte/components.modal-confirm";
import { ModalInputs } from "@empreinte/components.modal-inputs";
import EditPassword from "../EditPassword/editPassword";
import { useState } from "react";
import LockIcon from '@mui/icons-material/Lock';
import { Helpers } from "../../utils/helpers";
import UserSelect from "../UserSelect/userSelect";


function useActionMenu({ record }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const {acces_send} = Helpers()
  const {profiles_user_module} = useAppSelector(state =>state.ReducerUserData.modules)
  const isSuperAdmin = useAppSelector(state =>state.ReducerUserData.isSuperAdmin)
  const searchgroup = useAppSelector((state)=>state.UserReducer.filterUsers.selectedGroup)
  const searchText = useAppSelector((state)=>state.UserReducer.filterUsers.search)
  const { visible, showModal, handleCancel, EditUser,visibleShowPasswordModal, onFinishEditPassword,showModalPassword,handleCancelModalPassword,visibleDeletePopup,  showModalDeletePopup,handleCancelDeletePopup ,GetUsersFilteredDataAndByGroup,DeleteUsers} =
    Hooks();
    const  [passwords,setPasswords]=useState();
    const [userInfos,setUserInfos] = useState(null as any)
    const errorPassword=useAppSelector((state)=>state.UserReducer.errorPassword)
    const [on,setOn] = useState(record.row.enabled)
    const paginationProps=useAppSelector((state)=>state.UserReducer.paginationProps)
    const GetPasswords=(passwords:any)=>{
      setPasswords(passwords)
    }
    const getAssignedUserInfos = (user:any) =>{
      setUserInfos(user)
    }
  const edit = t("Profile.edit");
  const delet = t("Profile.delete");
  const detail = t("Profile.details");
  const passw = t("Profile.changePassword");
  const user = t("Profile.userAccount");

  return (
    <div>
      <div className="actionUser">
        {(profiles_user_module?.profiles_user_get_detail_action?.authorized || isSuperAdmin) && 
        <div has-permission="profiles_user_get_detail">
          <Tooltip placement="top" title={detail}>
            <RemoveRedEyeIcon className={"icon_action"} onClick={()=>showModal(profiles_user_module?.profiles_user_get_detail_action?.name)} />
          </Tooltip>
        </div>}
        { (profiles_user_module?.profiles_user_post_edit_user_action?.authorized || isSuperAdmin) && 
        <div has-permission="profiles_user_post_edit_user" >
          <Tooltip placement="top" title={edit}>
            <ModeEditIcon
              className={"icon_action"}
              onClick={() => EditUser(record,profiles_user_module?.profiles_user_post_edit_user_action?.name)}
            />
          </Tooltip>
        </div>}
        { (profiles_user_module?.profiles_user_edit_password_no_api_action?.authorized || isSuperAdmin) && 
        <div has-permission="profiles_user_edit_password_no_api" >
          <Tooltip placement="top" title={passw}>
            <LockIcon
              className={"icon_action"}
              onClick={()=>{ showModalPassword(profiles_user_module?.profiles_user_edit_password_no_api_action?.name)}}
            />
          </Tooltip>
        </div>}
            {/* will be added once the userlist api is integrated */}

        {on ? 
        ((profiles_user_module?.profiles_user_put_disable_user_action?.authorized || isSuperAdmin) && 
        <div has-permission="profiles_user_put_disable_user">
          <Tooltip placement="top" title={user}>
            <PersonRemove className={"icon_action"}
            onClick={()=>{dispatch(enableDisableUserAction('disable',record.row.id)); setOn(false);acces_send(`${profiles_user_module?.profiles_user_put_disable_user_action?.name}_action`) }} />
          </Tooltip>
        </div>
        ) 
        : 
        ((profiles_user_module?.profiles_user_put_enable_user_action?.authorized || isSuperAdmin) && 
        <div has-permission="profiles_user_put_enable_user" >
          <Tooltip placement="top" title={user}>
            <PersonAdd className={"icon_action"}
            onClick={()=>{dispatch(enableDisableUserAction("enable",record.row.id));setOn(true);acces_send(`${profiles_user_module?.profiles_user_put_enable_user_action?.name}_action`)}} />
          </Tooltip>
        </div> )}
        { (profiles_user_module?.profiles_user_profiles_delete_user_action?.authorized || isSuperAdmin) && 
        <div has-permission="profiles_user_profiles_delete_user" >
          <Tooltip placement="top" title={delet}>
            <DeleteIcon onClick={showModalDeletePopup} className={"icon_action"} />
          </Tooltip>
        </div>}

        {/*Modal Details*/}
      
        
        {/*Modal Details*/}
        <ModalInputs 
            className="config-ip-modal"
            open={visibleDeletePopup}
            dividers
            onClose={handleCancelDeletePopup}
            title={`${t('Profile.DeleteConfirmMessage')} : ${record.row.username} ?`}
            CancelBtnName={`${t('Profile.cancel')}`}
            onCancel={handleCancelDeletePopup}
            ConfirmBtnName={`${t('Profile.confirm')}`}
            onConfirm={() => {acces_send(`${profiles_user_module?.profiles_user_profiles_delete_user_action?.name}_action`);dispatch(setLoading(true));DeleteUsers([record.row.id],userInfos?.id).then((res)=>{dispatch(setLoading(false));
              GetUsersFilteredDataAndByGroup(paginationProps.pageSize,(paginationProps.current - 1)*paginationProps.pageSize, searchText, +searchgroup,paginationProps.order,paginationProps.desc)           
            })}}
            disabled={(userInfos as any)?.username?false:true}
            children={
              <UserSelect  GetUser={getAssignedUserInfos} userSelected={[record.row?.id]}/>
            }
            />
            <ModalInputs
          className="modal-group-details" 
          open={visibleShowPasswordModal}
          dividers
          title={`${t("ACTIONSLIST.profiles_user_edit_password_no_api")}`}
          onClose={()=>{dispatch({type:"SET_BTN_PASSWORD",payload:false});handleCancelModalPassword()}}
          CancelBtnName={t("Profile.cancel")}
          ConfirmBtnName={t("Profile.register")}
          btnstyle={{}}
          onCancel={()=>{dispatch({type:"SET_BTN_PASSWORD",payload:false});handleCancelModalPassword();}}
          onConfirm={()=>{onFinishEditPassword(record.row.id,passwords)}}
          disabled={errorPassword}
          children={
            <EditPassword GetPasswords={GetPasswords}  />
          }
        />

 
        <ModalInputs
          open={visible}
          dividers                    
          title={`${t("Profile.userDetails")} ${record.row.username}`}
          onClose={handleCancel}
          CancelBtnName={t("Profile.close")}
          onCancel={handleCancel}
          children={
                              
        
         
          <div className="group-details-modal">
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.lastNameUser")}</span>
                <span className="group-details-modal-value">{record?.row?.lastName ? record?.row?.lastName : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.firstNameUser")}</span>
                <span className="group-details-modal-value">{record?.row?.firstName ? record?.row?.firstName : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.userName")}</span>
                <span className="group-details-modal-value">{record?.row?.username ? record?.row?.username : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.email")}</span>
                <span className="group-details-modal-value">{record?.row?.email ? record?.row?.email : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.adresse")}</span>
                <span className="group-details-modal-value">{record?.row?.adresse ? record?.row?.adresse : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.postCode")}</span>
                <span className="group-details-modal-value">{record?.row?.postalCode ? record?.row?.postalCode : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.company")}</span>
                <span className="group-details-modal-value">{record?.row?.society ? record?.row?.society : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.city")}</span>
                <span className="group-details-modal-value">{record?.row?.city ? record?.row?.city : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.phone")}</span>
                <span className="group-details-modal-value">{record?.row?.phone ? record?.row?.phone : "--"}</span>
              </div>
              <div className="group-details-modal-container">
                <span className="group-details-modal-title">{t("Profile.lastConnexion")}</span>
                <span className="group-details-modal-value">{record?.row?.lastLogin ? record?.row?.lastLogin : "--"}</span>
              </div>
            </div>
          }
        />

       



      </div>
    </div>
  );
}

export default useActionMenu;
