import axios from "axios";
const search = window.location.search;
const token = new URLSearchParams(search).get("token");
const headers = {
    Authorization: `Bearer ${token}`,
  }
const url ="https:"+window.process.env.API_HOST_GLOBAL;
export const PostData = async (path, data) => {
    return await axios.post(`${url}${path}`, data, {headers});
};
export const GetData = async (path) => {
    return await axios.get(`${url}${path}`, {headers});
};
export const DeleteData = async (path) => {
    return await axios.delete(`${url}${path}`, {headers});
};
export const UpdateData = async (path, data) => {
    return await axios.put(`${url}${path}`, data, {headers});
};
export const SendAccess = async (path, sessionId, action) => {
    try {
      return await axios.post(`${url}${path}`, {
        EmpStatsQuery:{
        session_id: sessionId,
        action: action,
        }
      });
    } catch (e) {
      console.error("ERROR Send access :", e);
    }
  };
