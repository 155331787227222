import gql from "graphql-tag";

export const graphQL_shema = ()=> {

    const Get_All_Users = gql`
   mutation {
    
Users{
    totalCount
edges {
    
  node{ 
    Id
    FirstName
    LastName
    Username
    Email
    Password
    Vignette
    Adresse
    City
    Society
    PostalCode
    NumberPerson
    Phone
    Mobile
    Description
    ConnectionType
    

}
}
}
}
    `;
    const Delete_Users = gql`
    mutation DeleteUsers($input: DeleteUsersInput!) {
      DeleteUsers(input: $input) {
        code
        message
        deletedUsersIds
        transferredUserId
        transferredElements {
          transferredVideosIds
          transferredLivesIds
          TransferredLiveQuestionsIds
          transferredPodcastsIds
          transferredThemesIds
          transferredChannelsIds
        }
      }
    }
  `;

    return ({
        Get_All_Users,
        Delete_Users
    })

}