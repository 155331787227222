import React, { useState, useEffect } from "react";
import { Hooks } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useAppSelector } from "../../../../redux/hooks";
import "./userSelect.scss";
type User = {
  GetUser: (e: any) => any;
  userSelected: any;
};

const UserSelect = (props: User) => {
  const { t } = useTranslation();
  const allUsers = useAppSelector((state) => state.UserReducer.allUsers);
  const { GetAllUsers } = Hooks();
  const selectUser = (e: any, newValue: any) => {
    props.GetUser(newValue);
  };
  useEffect(() => {
    GetAllUsers();
  }, []);
  return (
    <div className="userSelection">
      {props.userSelected?.length === 1 ? (
        <div>
         {t("Profile.attributeUser")}
        </div>
      ) : (
        <div>
         {t("Profile.attributeUsers")}
        </div>
      )}
      {allUsers.filter(
        (u: any) =>
          !props.userSelected?.some(
            (selectedUser: any) => selectedUser === u.id
          )
      ).length ? (
        <div>
          <Autocomplete
            options={allUsers?.filter(
              (u: any) =>
                !props.userSelected?.some(
                  (selectedUser: any) => selectedUser === u.id
                )
            )}
            getOptionLabel={(option: any) => option.username}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label={t("Profile.user")} />
            )}
            onChange={selectUser}
          />
        </div>
      ) : null}
    </div>
  );
};
export default UserSelect;
